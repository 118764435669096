.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin-left: 30px;
}

.professor-info {
  display: flex;
  align-items: center;
  width: 1100px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  color: #333;
}

.professor-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-right: 20px;
}

.professor-details h2 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
}

.students-list {
  display: flex;


}

.student-tile {
  display: flex;
  width: 600px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.student-image {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 20px;
}

.student-info {
  flex: 1;
}

.student-info p {
  margin: 0;
  font-size: 16px;
  color: #333;
}
  