.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
}

.App-header {
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 0;

}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}


.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.prev-button{
  background-color: #28a9e2;
  position: fixed;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 100px;
  height: 50px;
  padding: 10px 20px;
}
.next-button {
  background-color: #28a9e2;
  position: fixed;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}



.prev-button:hover {
  background-color: #0056b3;
}


.prev-button:first-child {
  margin-left: 0;
}

.theme-switch {
  position: fixed;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  height: 20px;
  border-radius: 20px;
  background-color: #ccc;
  padding: 4px;
  cursor: pointer;
  z-index: 999;
}

.theme-switch:before {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.3s ease;
}

.theme-switch.dark:before {
  transform: translateX(100%);
  background-color: #000;
}

.theme-switch-label {
  font-size: 8px;
  color: #222;
  font-weight: bold;
  padding: 0 8px;
}


.App.day {
  background-color: white;
  color: black;
}

.App.night {
  background-color: #212121;
  color: white;
}

.App.night .App-header {
  background-color: #333;
}

.App.night .prev-button,
.App.night .next-button {
  background-color: #28a9e2;
  color: white;
}

.App.night .prev-button:hover,
.App.night .next-button:hover {
  background-color: #0056b3;
}

.App.night .theme-button {
  background-color: #28a9e2;
  color: white;
}

.App.night .theme-button:hover {
  background-color: #0056b3;
}


.blurred-content {
  filter: blur(5px);
  pointer-events: none;
}


.button--primary--yellow {
  color: black;
  border-width: 1px;
  min-width: 100px;
  margin: 0px 20px;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #FCFF4B;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button--primary--yellow:hover{
  background-color: #c5c900;
}

.button--primary--blue {
  color: white;
  border-width: 1px;
  min-width: 100px;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #28a9e2;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button--primary--blue:hover {
  background-color: #0056b3;
}


.button--secondary {
  color: white;
  min-width: 100px;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #044389;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button--secondary:hover {
  background-color: #044289c5;
}


.toggle--button{
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  padding: 10px 18px;
  /* background-color: #dbdbdb; */
  border-radius: 5px;


}


.toggle--btn--option{
  flex: 1;
  cursor: pointer;
  height: 100%;
  padding: 10px 18px;
  background-color: #28a9e2;
  color: white;
  border-radius: 5px;
}

.toggle--btn--option:hover{
  background-color: #abad0f;
}

.toggle--highlight{
  color: white;
  background-color: #044389;
}
