.roi-container {
  color: #28a9e2;
  font-size: 16px;
  width: 100%;
  text-align: center;
}

.roi-container h1{
  color: black;
}

.slidercontainer {
  min-width: 300px;  
  max-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 15px;
  color: white;
  transition: all 0.4s ease;
  padding-top: 60px;
  color: black;
}


.chart-wrapper {
  flex: 1;
}


.row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.slider-value {
  margin-right: 20px;
}

.slider {
  flex: 1;
}
.rc-slider {
  width: 60%;
}


.button {
  position: relative;
  width: 100px;
  height: 50px;
  padding: 10px 20px;
  color: #fff;
  background-color: #28a9e2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  align-items: center;
  align-content: center;
}

.button:hover {
  background-color: #0056b3;
}


.slider-wrapper-left, .slider-wrapper-right {
  width: 250px;
}