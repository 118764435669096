

.Dashboard_sidebar {
    background-color: #044389;
    height: 50vh;
    width: 200px;
    position: fixed;
    top: 100px;
    left: 20px;
    color: white;
    border-radius: 15px;
    margin-left: 20px;
    transition: all 0.4s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    z-index: 999;
}

.sidebar_section {
    min-width: 200px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    color: white;
    cursor: pointer;
    transition: font-size 0.3s ease;
}


.sidebar_section:hover {
    font-size: 1.1em;
}
