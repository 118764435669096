.chartcontainer {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.chartwrapper {
  color:#28a9e2;
  font-size: 20px;
  width: 100%;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
}

.chartwrapper > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ml-results-graph-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
}

.graph-name-group{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
  font-size: 1.5em;
  min-width: 450px;
}

.textheading{
  color: #28a9e2;
  align-items: center;
  font-size: 40px;
  margin-left: 550px;

}
.App.night .text {
  color: white;
}

.update-button {
  color: black;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #FCFF4B;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;


}

.update-button:hover {
  background-color: #c5c900;
}

.results-container {
  padding: 20px;
}

.results-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.results-description {
  margin-bottom: 10px;
}

.gray-button {
  color: black;
  border-radius: 5px;
  padding: 10px 18px;
  background-color:lightgrey;
  cursor: pointer;
  transition: all 0.3s ease;
}
.selected-button {
  color: black;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #FCFF4B;
  cursor: pointer;
  transition: all 0.3s ease;

}




.results-graph {
  margin-bottom: 20px;
}




