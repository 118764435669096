:root {
  --text-color: #222222;
  --bg-color: white;
  --border-color: #ccc;
  --input-color: white;
}

body.night {
  --text-color: white;
  --bg-color: #222222;
  --border-color: #28a9e2;
  --input-color: #222222;
}

.active-learning-container {
  width: 90%;
}

.active-learning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

}

.input-label {
  flex-basis: 30%;
  text-align: left;
  font-size: 14px;
  color: black;
}
.input-label-iteration {
  flex-basis: 100%;
  text-align: center;
  font-size: 1.5em;
  color: black;
}

.input-slider, .input-number, select {
  flex-basis: 70%;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--input-color);
  color: var(--text-color);
}

.form-inputs {
  grid-area: inputs;
}

.iteration-navigation {
  grid-area: navigation;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.iteration-navigation button, .active-learning button{
  min-width: 100px;
  padding: 5px;
  border-radius: 5px;
}

.displayed-content, .more-content {
  padding: 20px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow-x: auto;
  max-height: 300px;
  margin-top: 20px;
}

.displayed-content {
  grid-area: content;
}

.more-content {
  grid-area: more;
  display: none;
}

.more-content.active {
  display: block;
}

.annotated-content {
  overflow-x: auto;
  border: 1px solid #ccc;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #fff;
  color: var(--text-color);
  font-size: 0.8rem;
  display: none;
  white-space: nowrap;
}

.annotated-content.active {
  display: block;
}

.displayed-content pre, .more-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.annotated-content pre {
  white-space: pre;
}
.dropdowns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* aligns the dropdowns to the right */
  gap: 10px; /* adds some space between dropdowns */
}

