.chart-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.chart-row {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.chart-wrapper {
  flex: 1;
  color:#ff7f78;
  font-size: 16px;
  min-width: 400px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.text {
  color: #28a9e2;
  font-size: 40px;
}

.App.night .text {
  color: white;
}
