.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1500px;;
  gap: 20px; /* Space between grid items */
  color: #222222;
  margin: 30px;
}

.App.night .container {
  color: white;
}

.section {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
}

.subsection {
  padding: 20px;
  border-radius: 20px;
  min-height: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  
  /* Adding a subtle border for definition */
  border: 1px solid rgba(0, 0, 0, 0.1);
  
  /* Optional: Adding a subtle transition effect for hover */
  transition: all 0.3s ease;
}

.subsection:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 12px 24px rgba(0, 0, 0, 0.25);
  transform: translateY(-1px); /* Slight lift effect */
}

.graph-subsection{
  padding: 20px; /* Padding for better spacing */
  border-radius: 20px; /* Rounded corners */
  
  /* Adding a box shadow for a 3D effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  
  /* Adding a subtle border for definition */
  border: 1px solid rgba(0, 0, 0, 0.1);
  
  /* Optional: Adding a subtle transition effect for hover */
  transition: all 0.3s ease;
}

.graph-subsection:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 12px 24px rgba(0, 0, 0, 0.25);
  transform: translateY(-1px); /* Slight lift effect */
}

.table-section{
  flex: 3;
  overflow-y: auto;
  min-width: 350px;
}

.button {
  width: 250px;
  height: 50px;
  background-color: #28a9e2;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.button:focus {
  outline: none;
}

.input-section {
  display: flex;
  align-items: center;
}

.input-section input[type="file"] {
  margin-right: 10px;
}

/* .box button {
  color: white;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #044389;
  cursor: pointer;
  transition: all 0.3s ease;
} */

.App.night .input-section button {
  color: white;
  background-color: white;
  color: black;
}

.input-section button:hover {
  background-color: #1b7eab;
}

.box {
  padding: 20px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;

}

.slider-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App.night .box {
  border: 1px solid white;
  border-radius: 5px;
  padding: 20px;
  margin-right: 20px;
}

.small-text {
  font-size: 14px;
}

.medium-text {
  font-size: 16px;
}

.large-text {
  font-size: 18px;
}

.small-button {
  font-size: 14px;
}

.medium-button {
  font-size: 16px;
}

.large-button {
  font-size: 18px;
}

.file-input-field{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.graph-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.graphs-container{
  width: 100%;
}

.graph-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.canvas-cover{
  flex: 1;
  position: relative;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.selector-pair{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}


.upload-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 300px;
  width: 100%;
  max-height: 900px;
}

