.navbar {
  background-color: #044389;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App.night .navbar {
  background-color: #212121;
}

.navbar-button{
  margin: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.navbar-button a{
  color: white;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 18px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.5em;
}

.navbar-button a:hover{
  background-color: #245996;
  color: white;
}

.login-button {
  color: black;
  margin: 0px 20px;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #FCFF4B;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover{
  background-color: #c5c900;
}


.App.night .navbar li a {
  color: white;
}

.content {
  margin-left: 200px;
  padding-top: 80px;
}


@media (min-width: 768px) {
  .content {
    margin-left: 220px;
  }
}
.navbar .login-box input[type="text"],
.navbar .login-box input[type="password"] {
  padding: 5px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  background-color: lightgray;
}

.navbar .login-box button {
  margin-right: auto;
  background-color: #28a9e2;
  color: white;
  border: none;
  padding: 4px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.navbar .login-box button:hover {
  background-color: #1b7eab;
}

.login-box {
  margin-right: 60px;
}


/* App.css */
.modal {
  position: fixed; /* Changed from absolute to fixed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #044289d0;
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
}

.modal-form{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  margin: 20px;
}


.form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.form-group input{
  border-radius: 5px;
}

.modal-form button{
  color: black;
  margin: 0px 20px;
  border-radius: 5px;
  padding: 10px 18px;
  background-color: #FCFF4B;
  font-weight: bold;
  cursor: pointer;
}

.modal-cross-image{
  width: 30px;
  height: auto;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}





