
.title{
  color: #28a9e2;
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.home{
  margin: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  gap: 30px;
}
.textData{
  margin: 20px;
  font-size: 18px;
  color: black;
}

.App.night .textData{
  font-size: 18px;
  color: white;
}

.headings{
  color: #28a9e2;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: 0px;
}

.home-video {
  width: 90%;
  height: auto;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

